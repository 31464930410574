<template>
  <b-modal
    id="mailDialog"
    ref="mailDialog"
    :title="modalText"
    @shown="fetchClientUsers"
    @ok="sendMail"
    @cancel="closeModal"
    ok-title="Send Mail"
    footer-class="justify-content-center"
    size="xl"
  >
    <v-wait for="fetchingReport">
      <template slot="waiting">
        <content-placeholders :rounded="true">
          <content-placeholders-text :lines="20"></content-placeholders-text>
        </content-placeholders>
      </template>
      <div class="row py-2">
        <div class="col-sm-2">
          <label for="" class="px-2">Template:</label>
        </div>
        <div class="col-sm-7 pl-0">
          <multiselect
            v-model="mailTemplate"
            :options="templateOptions"
            :multiple="false"
            :close-on-select="true"
            :showLabels="false"
            track-by="id"
            label="name"
            placeholder="Select one"
            :class="['simple-select']"
            @close="templateChange()"
          >
          </multiselect>
        </div>
      </div>
      <div class="row py-2" v-if="showCustomMailContent">
        <div class="col-sm-2">
          <label for="" class="px-2">Custom Subject:</label>
        </div>
        <div class="col-sm-7 pl-0">
          <input
            type="text"
            v-model="customSubject"
            class="form-control rounded mt-1 mail-dialog-input"
            placeholder="Mail Subject"
          />
        </div>
      </div>
      <div class="row py-2" v-if="showCustomMailContent">
        <div class="col-sm-2">
          <label for="" class="px-2">Custom Mail Content:</label>
        </div>
        <div class="col-sm-7 pl-0">
          <textarea
            v-model="customContent"
            class="form-control rounded mt-1 mail-dialog-input"
            placeholder="Mail Content"
          />
        </div>
      </div>
      <div class="row py-2">
        <div class="col-sm-2">
          <label for="" class="px-2">Mail To:</label>
        </div>
        <div class="col-sm-7 pl-0">
          <multiselect
            v-model="receipients"
            :options="mailList"
            :multiple="true"
            :close-on-select="false"
            :showLabels="false"
            label="email"
            placeholder="Select Users"
            :class="['simple-select']"
          >
            <template
              slot="selection"
              v-if="receipients.length"
              slot-scope="{ values }"
            >
              {{ values.length }}
              {{ values.length | pluralize('user') }} selected
            </template>
            <template slot="beforeList" v-if="mailList.length">
              <li class="multiselect__elemet p-2 border-bottom">
                <span
                  @click="selectOrRemoveAllUsers()"
                  class="select-all-option"
                >
                  <b>{{ selectAllText }}</b></span
                >
              </li>
            </template>
          </multiselect>
        </div>
      </div>
    </v-wait>
  </b-modal>
</template>
<script charset="utf-8">
// api
import Multiselect from 'vue-multiselect'
export default {
  name: 'MailDialog',
  components: {
    Multiselect
  },
  props: {
    selectedClient: {
      type: Object,
      default: () => {
        return {}
      }
    }
  },
  data() {
    return {
      selectedGroup: null,
      mailTemplate: null,
      mailList: [],
      receipients: [],
      showCustomMailContent: false,
      customSubject: '',
      customContent: '',
      templateOptions: [
        { id: 'reminder_template', name: 'Reminder Template' },
        { id: 'terminated_template', name: 'Terminated Template' },
        { id: 'custom_template', name: 'Custom Template' }
      ]
    }
  },
  computed: {
    modalText() {
      return this.selectedClient.client_name + ' - Send Mail'
    },
    selectAllText() {
      return this.allUsersSelected ? 'Clear All' : 'Select All'
    },
    allUsersSelected() {
      return this.receipients.length === this.mailList.length
    }
  },
  methods: {
    fetchClientUsers() {
      this.clearData()
      this.$wait.start('fetchingReport')
      this.$http
        .get('/api/groups/' + this.selectedClient.id + '?super_admin=true', {
          handleErrors: false
        })
        .then(res => {
          this.selectedGroup = res.data

          this.mailList = this.selectedGroup.users
            .filter(user => user.selected)
            .map(user => {
              return { id: user.id, email: user.email }
            })

          this.$wait.end('fetchingReport')
        })
    },
    clearData() {
      this.mailTemplate = null
      this.receipients = []
      this.mailList = []
      this.showCustomMailContent = false
      this.customSubject = ''
      this.customContent = ''
    },
    selectOrRemoveAllUsers() {
      this.receipients = this.allUsersSelected ? [] : this.mailList
    },
    closeModal() {
      this.$bvModal.hide('mailDialog')
    },
    templateChange() {
      this.showCustomMailContent =
        this.mailTemplate.id === 'custom_template' ? true : false
    },
    sendMail() {
      this.$http
        .post(
          '/api/super_admin/client_activity_reports/send_email',
          {
            user_ids: this.receipients.map(user => user.id),
            group_id: this.selectedClient.id,
            mail_template: this.mailTemplate.id,
            custom_subject: this.customSubject,
            custom_content: this.customContent
          },
          {
            handleErrors: false
          }
        )
        .then(() => {
          this.$toasted.show('Mail Request submitted successfully.', {
            icon: 'user-circle',
            type: 'success'
          })
        })
    }
  }
}
</script>
<style lang="scss" scoped>
.mail-dialog-input {
  border-color: #e8e8e8;
  font-size: 16px;
}
</style>

<template>
  <b-row>
    <b-col sm="12">
      <v-wait for="fetchingClients">
        <template slot="waiting">
          <content-placeholders :rounded="true">
            <content-placeholders-text :lines="20"></content-placeholders-text>
          </content-placeholders>
        </template>
        <div class="row">
          <div class="col-sm-3 offset-sm-9 mb-3">
            <b-input-group>
              <div class="input-group-prepend">
                <button type="button" class="btn btn-secondary">
                  <i class="fa fa-search"></i>
                </button>
              </div>
              <b-form-input
                type="text"
                v-model="filter"
                placeholder="Search"
                ref="search"
              ></b-form-input>
            </b-input-group>
          </div>
        </div>
        <div class="row">
          <div class="col-sm-12"></div>
        </div>

        <b-table
          :items="clientsList"
          :fields="fields"
          :show-empty="true"
          :current-page="currentPage"
          :per-page="perPage"
          :filter="filter"
          @filtered="onFiltered"
          empty-text="No records found."
          responsive="md"
          striped
          hover
        >
          <template v-slot:cell(client_name)="data">
            <div>{{ data.item.client_name }} ({{ data.item.id }})</div>
          </template>
          <template v-slot:cell(start_date)="data">
            <div>{{ formatDate(data.item.start_date) }}</div>
          </template>
          <template v-slot:cell(end_date)="data">
            <div>{{ formatDate(data.item.end_date) }}</div>
          </template>
          <template v-slot:cell(actions)="data">
            <a href="#" @click="showActivityReport(data.item.id)" class="pl-2"
              >View Activity Report
            </a>
            <a href="#" class="pl-2" @click="setAsTestGroup(data.item.id)"
              >Set As Test
            </a>
            <a href="#" @click="showPricingSchedule(data.item.id)" class="pl-2"
              >View Access
            </a>

            <router-link
              tag="button"
              class="btn btn-link collapsed button-pad pr-2 edit-btn-link"
              :to="{
                path: '/super_admin/client_pricing_schedules/' + data.item.id,
                params: { mode: 'Edit' }
              }"
              >Edit Access</router-link
            >
            <a
              href="#"
              @click="showMailDialog(data.item.id)"
              class="pl-2 text-nowrap"
            >
              Send Mail
            </a>
          </template>
        </b-table>
        <b-row>
          <b-col md="6">
            <b-pagination
              :total-rows="totalRows"
              :per-page="perPage"
              v-model="currentPage"
            >
            </b-pagination>
          </b-col>
        </b-row>
      </v-wait>
      <show-pricing-schedule
        :selectedPricingSchedule="selectedPricingSchedule"
      ></show-pricing-schedule>
      <ShowClientActivity :selectedClient="selectedClient">
      </ShowClientActivity>
      <MailDialog :selectedClient="selectedClient"></MailDialog>
    </b-col>
  </b-row>
</template>

<script>
// ui components
import ShowPricingSchedule from './ShowPricingSchedule'
import ShowClientActivity from './ShowClientActivity'
import MailDialog from './MailDialog'
import superAdminUsersAPI from '@/modules/super_admin/api/users'
import * as moment from 'moment'
export default {
  name: 'ClientPricingSchedules',
  components: {
    ShowPricingSchedule,
    ShowClientActivity,
    MailDialog
  },
  created() {
    this.getClients(true)
    this.fetchingClientPricingSchedules()
  },
  data() {
    return {
      perPage: 20,
      totalRows: 1,
      filter: null,
      currentPage: 1,
      clientsList: [],
      clientPricingSchedules: [],
      selectedPricingSchedule: {},
      selectedClient: {},
      deleteConfirmationMessage:
        'Along with pricing schedules, related single click defaults for this group will also get deleted. Are you sure you want to delete?',
      deleteConfirmationTitle: 'Delete Client Pricing Schedule',
      fields: [
        { key: 'client_name', label: 'Client Name', sortable: true },
        { key: 'group_type', label: 'Group Type', sortable: true },
        { key: 'company_type', label: 'Company Type', sortable: true },
        { key: 'start_date', label: 'Start Date', sortable: true },
        { key: 'end_date', label: 'End Date', sortable: true },
        { key: 'actions', label: 'Actions', sortable: false }
      ]
    }
  },
  computed: {},
  methods: {
    formatDate(date) {
      return moment(date).format('YYYY-MM-DD')
    },
    getClients(loadPricing) {
      this.$wait.start('fetchingClients')

      return superAdminUsersAPI.getClients().then(res => {
        this.clientsList = res
        this.totalRows = this.clientsList.length
        if (loadPricing) this.fetchingClientPricingSchedules()
        else this.$wait.end('fetchingClients')
      })
    },
    setSelectedPricingSchedule(groupId) {
      this.selectedPricingSchedule = this.clientPricingSchedules.find(
        cps => cps.group_id === groupId
      )
    },
    setSelectedClient(groupid) {
      this.selectedClient = this.clientsList.find(c => c.id === groupid)
    },
    resetSelectedCPS() {
      this.selectedPricingSchedule = {}
    },
    fetchingClientPricingSchedules() {
      this.$wait.start('fetchingClients')
      this.$http
        .get('/api/super_admin/client_pricing_schedules', {
          handleErrors: false
        })
        .then(res => {
          this.clientPricingSchedules = res.data.client_pricing_schedules.filter(
            cps => !(cps.group_type === 'Client Group' && cps.id == null)
          )
        })
        .then(() => {
          this.$wait.end('fetchingClients')
        })
    },
    displayConfigurations(configurations) {
      return JSON.stringify(configurations)
    },

    onFiltered(filteredItems) {
      this.totalRows = filteredItems.length
      this.currentPage = 1
    },
    showPricingSchedule(pricingSchedule) {
      this.setSelectedPricingSchedule(pricingSchedule)
      this.$bvModal.show('showPricingSchedule')
    },
    showActivityReport(pricingSchedule) {
      this.setSelectedClient(pricingSchedule)
      this.$bvModal.show('showClientActivity')
    },
    showMailDialog(item) {
      this.setSelectedClient(item)
      this.$bvModal.show('mailDialog')
    },
    setAsTestGroup(id) {
      this.$http
        .post(
          '/api/test_groups',
          { group_id: id },
          {
            handleErrors: false
          }
        )
        .then(() => {
          this.getClients(false)
          this.$toasted.show('Group marked as test group successfully.', {
            icon: 'user-circle',
            type: 'success'
          })
        })
    }
  }
}
</script>

<style lang="scss" scoped>
.collapse_td_tr {
  tr {
    vertical-align: middle !important;
  }
  td {
    padding: 0.1rem 0.75rem !important;
  }
  .table {
    background-color: transparent !important;
  }
  .collapsed > .when-opened,
  :not(.collapsed) > .when-closed {
    display: none;
  }
  .button-pad {
    padding: 0px !important;
    cursor: pointer;
  }
}

.edit-btn-link {
  vertical-align: baseline;
}
</style>

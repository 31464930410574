<template>
  <PageCard
    pageName="Client Activity Reports"
    :iconClass="['fa', 'fa-user-secret', 'fa-lg']"
  >
    <template slot="page_content">
      <b-tabs>
        <b-tab active>
          <template slot="title">
            <h6 class="tab-title font-weight-bold">All Clients</h6>
          </template>
          <AllClients></AllClients>
        </b-tab>
        <b-tab>
          <div class="row">
            <div class="col-sm-12">
              <b-card
                class="std-border"
                body-class="p-1 activity-filter-form"
                header-class="p-2 header-with-bg"
              >
                <div slot="header">
                  <div class="row fit-to-center">
                    <div class="col-sm-12">
                      <div class="d-flex">
                        <h5 class="mb-0 font-weight-bold">
                          Query Builder
                        </h5>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="p-3">
                  <div class="row">
                    <div class="col-sm-6">
                      <v-wait for="fetchingGroups">
                        <template slot="waiting">
                          <content-placeholders :rounded="true">
                            <content-placeholders-text
                              :lines="9"
                            ></content-placeholders-text>
                          </content-placeholders>
                        </template>
                        <b-form-group
                          label="Group"
                          label-for="group"
                          :label-cols="3"
                          :horizontal="true"
                          class=""
                        >
                          <multiselect
                            v-model="clients"
                            :options="clientsList"
                            :multiple="false"
                            :close-on-select="true"
                            :showLabels="false"
                            track-by="id"
                            label="client_name"
                            :placeholder="clients.length ? '' : 'Select Client'"
                            :class="[
                              {
                                'is-invalid': $v.clients.$error,
                                'is-valid': !$v.clients.$invalid
                              },
                              'simple-select group-select highlight-group-title form-control rounded'
                            ]"
                            @close="getGroupUsers"
                          >
                            <template
                              slot="selection"
                              v-if="clients.length"
                              slot-scope="{ values }"
                            >
                              {{ values.length }}
                              {{ values.length | pluralize('group') }} selected
                            </template>
                          </multiselect>
                          <b-form-invalid-feedback v-if="!$v.clients.required"
                            >at least one group
                            required</b-form-invalid-feedback
                          >
                        </b-form-group>

                        <b-form-group
                          label="User"
                          label-for="user"
                          :label-cols="3"
                          :horizontal="true"
                          class=""
                        >
                          <multiselect
                            v-model="users"
                            :options="usersList"
                            :multiple="true"
                            :close-on-select="false"
                            :showLabels="false"
                            :loading="fetchingUsers"
                            track-by="id"
                            label="name"
                            :placeholder="clients.length ? '' : 'Select Users'"
                            :class="[
                              {
                                'is-invalid': $v.users.$error,
                                'is-valid': !$v.users.$invalid
                              },
                              'simple-select group-select highlight-group-title form-control rounded'
                            ]"
                          >
                            <!-- @close="validateUsers" -->
                            <template
                              slot="selection"
                              v-if="users.length"
                              slot-scope="{ values }"
                            >
                              {{ values.length }}
                              {{ values.length | pluralize('user') }} selected
                            </template>
                            <template slot="beforeList" v-if="usersList.length">
                              <li class="multiselect__elemet p-2 border-bottom">
                                <span
                                  @click="selectOrRemoveAllUsers()"
                                  class="select-all-option"
                                >
                                  <b>{{ selectAllText }}</b></span
                                >
                              </li>
                            </template>
                          </multiselect>
                          <b-form-invalid-feedback v-if="!$v.users.required"
                            >at least one user required</b-form-invalid-feedback
                          >
                        </b-form-group>

                        <b-form-group
                          label="Report Type"
                          label-for="report_type"
                          :label-cols="3"
                          :horizontal="true"
                          class=""
                        >
                          <multiselect
                            v-model="reportTypes"
                            :options="reportTypesList"
                            :multiple="true"
                            :close-on-select="false"
                            :showLabels="false"
                            :placeholder="
                              reportTypes.length ? '' : 'Select Report Types'
                            "
                            track-by="value"
                            label="text"
                            :class="[
                              {
                                'is-invalid': $v.reportTypes.$error,
                                'is-valid': !$v.reportTypes.$invalid
                              },
                              'simple-select group-select highlight-group-title form-control rounded'
                            ]"
                          >
                            <template
                              slot="selection"
                              v-if="reportTypes.length"
                              slot-scope="{ values }"
                            >
                              {{ values.length }}
                              {{ values.length | pluralize('type') }} selected
                            </template>
                          </multiselect>
                          <b-form-invalid-feedback
                            v-if="!$v.reportTypes.required"
                            >at least one report type
                            required</b-form-invalid-feedback
                          >
                        </b-form-group>
                        <!-- <b-form-group
                      label="Date Range"
                      label-for="date_range"
                      :label-cols="3"
                      :horizontal="true"
                      class=""
                    >
                      <div class="row">
                        <div
                          class="col-sm-6 date-col"
                          :class="{
                            'is-invalid': $v.startDate.$error,
                            'is-valid': !$v.startDate.$invalid
                          }"
                        >
                          <datepicker
                            v-model="startDate"
                            placeholder="Start Date"
                            format="yyyy-MM-dd"
                            :bootstrap-styling="true"
                            class="start-date-picker"
                            @selected="validateStartDate()"
                          ></datepicker>
                          <b-form-invalid-feedback v-if="!$v.startDate.required"
                            >can't be blank</b-form-invalid-feedback
                          >
                          <b-form-invalid-feedback v-if="!$v.startDate.isBefore"
                            >should be earlier than End
                            Date</b-form-invalid-feedback
                          >
                        </div>

                        <div
                          class="col-sm-6 date-col"
                          :class="{
                            'is-invalid': $v.endDate.$error,
                            'is-valid': !$v.endDate.$invalid
                          }"
                          @focusout="validateEndDate()"
                        >
                          <datepicker
                            v-model="endDate"
                            placeholder="End Date"
                            format="yyyy-MM-dd"
                            :bootstrap-styling="true"
                            class="start-date-picker"
                            @selected="validateEndDate()"
                          ></datepicker>
                          <b-form-invalid-feedback v-if="!$v.endDate.required"
                            >can't be blank</b-form-invalid-feedback
                          >
                          <b-form-invalid-feedback v-if="!$v.endDate.isBefore"
                            >should be after Start Date</b-form-invalid-feedback
                          >
                        </div>
                      </div>
                    </b-form-group> -->
                      </v-wait>
                    </div>

                    <!-- <div class="col-sm-6 px-5" v-if="showSubscription">
                  <b-card
                    class="std-border my-3 rounded card--report-schedule"
                    body-class="p-2 schedule-body"
                    header-class="p-2 schedule-header"
                  >
                    <div slot="header">
                      <div class="row fit-to-center">
                        <div class="col-sm-12">
                          <b> Schedule Monthly Report </b>
                        </div>
                      </div>
                    </div>
                    <v-wait for="fetchingUserSchedule">
                      <template slot="waiting">
                        <content-placeholders :rounded="true">
                          <content-placeholders-text
                            :lines="5"
                          ></content-placeholders-text>
                        </content-placeholders>
                      </template>
                      <div>
                        <p>
                          Enabling this feature will send a monthly user
                          activity report applying these filters(except date
                          range) to your registered email.
                          <br />
                          <b class="d-inline-block pt-2">Note:</b> Date range
                          will always between start date and end date of month
                          for scheduled reports.
                        </p>
                      </div>
                      <div class="text-center mt-2">
                        <button
                          type="submit"
                          class="btn btn-secondary rounded py-1 px-2 mt-1 fw-btn"
                          @click="disableScheduledReport"
                          :disabled="!reportScheduled"
                        >
                          Disable
                        </button>
                        <button
                          type="submit"
                          class="btn btn-success rounded py-1 px-2 mt-1 mx-3 btn-enable fw-btn"
                          @click="updateScheduledReport()"
                          v-if="reportScheduled"
                        >
                          <b>Update</b>
                        </button>
                        <button
                          type="submit"
                          class="btn btn-success rounded py-1 px-2 mt-1 mx-3 btn-enable fw-btn"
                          @click="enableScheduledReport()"
                          v-else
                        >
                          <b>Enable</b>
                        </button>
                      </div>
                    </v-wait>
                  </b-card>
                </div> -->
                  </div>

                  <div class="row pt-3">
                    <div class="col-6 d-flex justify-content-center">
                      <div class="px-1">
                        <button
                          type="button"
                          class="btn fw-btn btn-secondary rounded"
                        >
                          <!-- @click="resetQuery" -->
                          Reset
                        </button>
                      </div>
                      <div class="px-1">
                        <button
                          type="button"
                          class="btn fw-btn btn-primary rounded"
                        >
                          <!-- @click="fetchActivityReport" -->
                          Filter
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </b-card>
            </div>
          </div>

          <b-card
            class="std-border my-3 card--activity-report"
            body-class="p-1"
            header-class="p-2 header-with-bg"
            v-if="showReports"
          >
            <div slot="header">
              <div class="row fit-to-center">
                <div class="col-sm-6">
                  <div class="page-header-left">
                    <div>
                      <h5 class="mb-0">
                        <b>Query Results</b>
                      </h5>
                    </div>
                  </div>
                </div>
                <div class="col-sm-6">
                  <div class="page-header-right">
                    <div class="">
                      <i
                        class="cuis-cloud-download font-2xl download-icon"
                        title="Download CSV"
                        @click="downloadCSVReport()"
                      ></i>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div v-if="fetchingReports">
              <content-placeholders :rounded="true">
                <content-placeholders-text
                  :lines="15"
                ></content-placeholders-text>
              </content-placeholders>
            </div>
            <b-table
              striped
              hover
              :fields="reportFields"
              :items="userReports"
              small
              responsive
            >
              <template v-slot:cell(event_detail)="data">
                <div v-if="data.item.event_detail">
                  {{ data.item.event_detail }}
                </div>
                <div v-else>
                  {{ data.item.fi_name }}
                </div>
              </template>
              <template v-slot:cell(bank_id)="data">
                <div v-if="data.item.bank_id">
                  {{ data.item.bank_id }}
                </div>
                <div v-else>
                  {{ data.item.fi_id }}
                </div>
              </template>
            </b-table>
          </b-card>
        </b-tab>
      </b-tabs>
    </template>
  </PageCard>
</template>

<script>
import { required } from 'vuelidate/lib/validators'
import adminUsersAPI from '@/modules/admin/api/users'
// import adminUserReportAPI from '@/modules/admin/api/user_activity_report'
// import superAdminGroupsAPI from '@/modules/super_admin/api/groups'
import partnerGroupsAPI from '@/api/finapps/partner_admin/groups'
import superAdminUsersAPI from '@/modules/super_admin/api/users'
import PageCard from '@/modules/core/components/layouts/PageCard'
import Multiselect from 'vue-multiselect'
// import Datepicker from 'vuejs-datepicker'
import AllClients from './AllClients'

export default {
  name: 'ClientActivityReports',
  components: {
    PageCard,
    Multiselect,
    // Datepicker,
    AllClients
  },
  data() {
    return {
      clientUsers: [],
      users: [],
      clients: [],
      reportTypes: [],
      usersList: [],
      clientsList: [],
      userReports: [],
      //   endDate: null,
      //   startDate: null,
      fetchingUsers: false,
      fetchingReports: false,
      showReports: false,
      reportFields: [],
      reportScheduled: false,
      reportTypesList: [
        { value: 'login', text: 'Login - UI' },
        { value: 'login - Direct URL', text: 'Login - Direct URL' },
        { value: 'download', text: 'Download' },
        { value: 'report_view', text: 'Report View - UI' },
        { value: 'report_view - Direct URL', text: 'Report View - Direct URL' },
        { value: 'bank_service_order', text: 'Bank Service Order' },
        { value: 'peer_group_created', text: 'Peer Group Created' },
        { value: 'fi_order', text: 'Financial Institution Order' }
      ]
    }
  },
  mounted() {},
  methods: {
    getClients() {
      this.$wait.start('fetchingClients')

      return superAdminUsersAPI.getClients().then(res => {
        this.clientsList = res
        this.$wait.end('fetchingGroups')
      })
    },
    getGroupUsers() {
      this.$v.clients.$touch()
      if (!this.$v.clients.$error) {
        this.users = []
        this.usersList = []
        this.fetchingUsers = true
        let groupIDs = this.clients.map(group => group.id)
        let usersAPI = this.isSuperAdmin
          ? superAdminUsersAPI
          : this.isPartnerAdmin
          ? partnerGroupsAPI
          : adminUsersAPI

        return usersAPI.groupUsers(groupIDs).then(res => {
          this.usersList = res.users
          this.fetchingUsers = false
        })
      }
    }
  },
  validations: {
    clients: {
      required
    },
    users: {
      required
    },
    reportTypes: {
      required
    }
    // startDate: {
    //   required,
    //   isBefore(date) {
    //     if (!date || !this.endDate) {
    //       return true
    //     }
    //     let formattedEndDate = moment(this.endDate)
    //     return moment(date).isBefore(formattedEndDate)
    //   }
    // },
    // endDate: {
    //   required,
    //   isAfter(date) {
    //     if (!date || !this.startDate) {
    //       return true
    //     }
    //     let formattedStartDate = moment(this.startDate)
    //     return moment(date).isAfter(formattedStartDate)
    //   }
    // }
  }
}
</script>

<template>
  <b-modal
    id="showClientActivity"
    ref="showClientActivity"
    :title="modalText"
    @shown="fetchClientActivity"
    @ok="closeModal"
    ok-only
    ok-title="Close"
    size="xl"
  >
    <v-wait for="fetchingReport">
      <template slot="waiting">
        <content-placeholders :rounded="true">
          <content-placeholders-text :lines="20"></content-placeholders-text>
        </content-placeholders>
      </template>
      <div class="row">
        <div class="col-sm-3 mb-2 align-content-center">
          <b-form-checkbox
            id="enterprisePricing"
            name="Enterprise Pricing"
            ref="enterprisePricing"
            class=" rounded d-inline b-0 pr-2 mb-1"
            v-model="showUserActivity"
            @input="fetchClientUserActivity()"
            :value="true"
            :unchecked-value="false"
          >
            <span class="col-form-label">Show User Activity</span>
          </b-form-checkbox>
        </div>
        <div class="col-sm-3 offset-sm-6 mb-3" v-if="showUserActivity">
          <b-input-group>
            <div class="input-group-prepend">
              <button type="button" class="btn btn-secondary">
                <i class="fa fa-search"></i>
              </button>
            </div>
            <b-form-input
              type="text"
              v-model="filter"
              placeholder="Search"
              ref="search"
            ></b-form-input>
          </b-input-group>
        </div>
      </div>
      <b-table
        striped
        :items="users"
        :fields="fields"
        :filter="filter"
        caption-top
        responsive
        show-empty
        empty-text="No User Data Available"
        v-if="showUserActivity"
      >
      </b-table>
    </v-wait>
    <div class="trend-chart-container" v-if="!showUserActivity">
      <div id="column-chart" class="col-chart px-3"></div>
      <div v-if="noDataFound">
        <h5 class="text-center">No Data Available for this Client</h5>
      </div>
    </div>
  </b-modal>
</template>

<script charset="utf-8">
// api
import { mapState } from 'vuex'
import superAdminUsersAPI from '@/modules/super_admin/api/users'
import Highcharts from 'highcharts'

export default {
  name: 'ShowClientActivity',
  props: {
    selectedClient: {
      type: Object,
      default: () => {
        return {}
      }
    }
  },
  data() {
    return {
      fields: [
        { key: 'id', label: 'Id', sortable: true },
        { key: 'email', label: 'Email', sortable: true },
        { key: 'user_name', label: 'Name', sortable: true },
        {
          key: 'visits_30_day',
          label: 'Visits Last 30 days',
          sortable: true
        },
        {
          key: 'visits_60_day',
          label: 'Visits Last 60 days',
          sortable: true
        },
        {
          key: 'visits_90_day',
          label: 'Visits Last 90 days',
          sortable: true
        },
        {
          key: 'reports_30_day',
          label: 'Reports 30 days',
          sortable: true
        },
        {
          key: 'reports_60_day',
          label: 'Reports 60 days',
          sortable: true
        },
        {
          key: 'reports_90_day',
          label: 'Reports 90 days',
          sortable: true
        },
        {
          key: 'downloads_30_day',
          label: 'Downloads 30 days',
          sortable: true
        },
        {
          key: 'downloads_60_day',
          label: 'Downloads 60 days',
          sortable: true
        },
        {
          key: 'downloads_90_day',
          label: 'Downloads 90 days',
          sortable: true
        },
        {
          key: 'api_calls_90_day',
          label: 'API calls 90 days',
          sortable: true
        },
        {
          key: 'query_subscription_users',
          label: 'Query Subscription Users',
          sortable: true
        },

        {
          key: 'direct_access_users_90_day',
          label: 'Direct Access users 90 days',
          sortable: true
        },
        {
          key: 'custom_peer_users_90_day',
          label: 'Custom Peer Users 90 days',
          sortable: true
        }
      ],
      users: [],
      filter: null,
      showUserActivity: false,
      noDataFound: false
    }
  },
  computed: {
    ...mapState({
      isSidebarExpanded: state => state.isSidebarExpanded
    }),
    modalText() {
      return this.selectedClient.client_name + ' - Client/User Activity Report'
    }
  },
  methods: {
    fetchClientActivity() {
      this.showUserActivity = false
      this.noDataFound = false
      this.$wait.start('fetchingReport')
      superAdminUsersAPI.getClientActivity(this.selectedClient.id).then(res => {
        if (res.client_data_scoring.length > 0) {
          this.clientData = res.client_data_scoring[0]
          this.drawChart()
        } else {
          this.noDataFound = true
        }
        this.$wait.end('fetchingReport')
      })
    },
    fetchClientUserActivity() {
      if (!this.showUserActivity) this.fetchClientActivity()
      else {
        this.$wait.start('fetchingReport')
        superAdminUsersAPI
          .getClientUserActivity(this.selectedClient.id)
          .then(res => {
            this.users = res.client_activity_reports
            this.$wait.end('fetchingReport')
          })
      }
    },
    closeModal() {
      this.$bvModal.hide('showClientActivity')
    },
    reflowChart() {
      setTimeout(
        function() {
          this.columnChart.reflow()
        }.bind(this),
        250
      )
    },
    drawChart() {
      this.columnChart = Highcharts.chart('column-chart', {
        credits: {
          enabled: false
        },
        exporting: {
          enabled: false
        },
        chart: {
          type: 'column',
          marginTop: 25
        },
        title: {
          text: null
        },
        tooltip: {
          enabled: false
        },
        legend: {
          verticalAlign: 'bottom',
          squareSymbol: false,
          symbolRadius: 0,
          itemMarginTop: -11,
          itemMarginBottom: -11
        },
        plotOptions: {
          series: {
            animation: false,
            dataLabels: {
              enabled: true,

              style: {
                fontSize: '11px',
                color: '#666666',
                fontWeight: 'normal'
              },
              crop: false,
              overflow: 'none'
            },
            pointWidth: 40,
            groupPadding: 0.07,
            pointPadding: 0
          }
        },
        xAxis: {
          categories: [
            'Total Users',
            'Registered Users',
            'Active Users',
            'Visits',
            'Direct Report Views',
            'Downloads',
            'API Calls',
            'Query Subscription Users',
            'Direct Access Users',
            'Custom Peer Users'
          ],
          gridLineWidth: 1,
          labels: {
            y: 16,
            style: {
              fontSize: '0.8rem',
              fontWeight: 'normal'
            }
          }
        },
        yAxis: {
          min: 0,
          maxPadding: 0.1,
          minPadding: 0.1,
          gridLineWidth: 0,
          lineWidth: 1,
          plotLines: [
            {
              color: '#e6e6e6',
              width: 1,
              value: 0
            }
          ],
          title: {
            text: null
          }
        },
        series: [
          {
            name: 'Last 30 Days',
            data: [
              null,
              null,
              this.clientData.active_users_30_day,
              this.clientData.visits_30_day,
              this.clientData.reports_30_day,
              this.clientData.downloads_30_day
            ],
            color: '#537CD1'
          },
          {
            name: 'Last 60 Days',
            data: [
              null,
              null,
              this.clientData.active_users_60_day,
              this.clientData.visits_60_day,
              this.clientData.reports_60_day,
              this.clientData.downloads_60_day
            ],
            color: '#FFDC88'
          },
          {
            name: 'Last 90 Days',
            data: [
              this.clientData.total_users,
              this.clientData.registered_users,
              this.clientData.active_users_90_day,
              this.clientData.visits_90_day,
              this.clientData.reports_90_day,
              this.clientData.downloads_90_day,
              this.clientData.api_calls_90_day,
              this.clientData.query_subscription_users,
              this.clientData.direct_access_users_90_day,
              this.clientData.custom_peer_users_90_day
            ],
            color: '#414142'
          }
        ]
      })
    }
  },
  watch: {
    isSidebarExpanded: function() {
      this.reflowChart()
    }
  }
}
</script>
